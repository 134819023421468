import React from 'react'
import Img from 'gatsby-image'
import slugify from 'slugify'
import jsonData from './assets/data/data.json'
import usePageQuery from '../../pageQuery'

import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import * as S from './styles'

interface ISteps {
  text: string;
}

const ComoAtivarAFolhaDePagamentos = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const steps: ISteps[] = jsonData

  return (
    <S.Section className='' id='como-ativar-folhda-de-pagamentos'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 mb-4 mb-md-0'>
            <Img fluid={data.bannerComoAtivarFolhaDePagamentos.fluid} alt='Notebook com o Internet Banking Empresas aberto na tela da home' />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1'>
            <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 fw-600 text-sora text-white font-sora'><span className='d-block'>Como ativar a folha</span> de pagamentos?</h2>
            <S.Vantagens className=''>
              {steps.map((card: ISteps, index: number) => (
                <S.Vantagem key={slugify(card.text)} className='d-flex align-items-center'>
                  <div className='mr-3'>
                    <S.IconCircle className='d-flex justify-content-center align-items-center'>
                      <span className='fs-18 lh-22 fw-700 font-inter text-white'>{index + 1}</span>
                    </S.IconCircle>
                  </div>
                  <div>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white font-sora mb-0' dangerouslySetInnerHTML={{ __html: card.text }} />
                  </div>
                </S.Vantagem>
              ))}
            </S.Vantagens>
            <p className='fs-14 lh-18 text-white font-sora mb-0'><span className='fw-700'>Quer simplificar?</span> Baixe nosso modelo de planilha e traga sua folha de pagamentos para o Inter Empresas</p>
            <div>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_4',
                    section_name: 'Como ativar a folha de pagamentos?',
                    element_name: 'Baixar planilha agora',
                    element_action: 'click button',
                    redirect_url: 'https://marketing.bancointer.com.br/empresas/documentos/Template-Folha-de-Pagamento.xlsx',
                  })
                }}
                href='https://marketing.bancointer.com.br/empresas/documentos/Template-Folha-de-Pagamento.xlsx'
                className='btn--lg btn btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mw-100'
              >
                Baixar planilha agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ComoAtivarAFolhaDePagamentos
