import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'
import styled from 'styled-components'

// Background
import BackgroundMD from '../../assets/images/bg-md-hero-folha-de-pagamentos.png'
import BackgroundLG from '../../assets/images/bg-lg-hero-folha-de-pagamentos.png'
import BackgroundXL from '../../assets/images/bg-xl-hero-folha-de-pagamentos.png'

export const Section = styled.div`
  background-color: ${green[500]};
  padding-top: 56px;
  padding-bottom: 16px;

  .btn {
    height: 48px;
  }

  @media ${device.tablet} {
    height: 510px;
    padding-top: 0;
    padding-bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(${BackgroundMD});
  }

  @media ${device.desktopLG} {
    height: 580px;
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    height: 840px;
    background-image: url(${BackgroundXL});
  }

  .buttons-container {
    margin-top: 40px;
  }
`
