import React from 'react'

import * as S from './styles'
import json from './assets/data/data.json'

import DigitalAccount from 'inter-frontend-svgs/lib/orangeds/MD/digital-account'
import Credit from 'inter-frontend-svgs/lib/orangeds/MD/credit'
import ForkKnife from '@interco/icons/build-v4/orangeds/MD/fork-knife'

import { IconProps } from 'src/components/Layout/Footer/Footer.interface'
import { green } from 'src/styles/colors'

import { IFolhaDePagamentoProps } from '../../types'
interface IVantagens {
  icon: string;
  text: string;
}

const FazendoOPagamentoDeSalarioNoInterEmpresas = ({ sendDatalayerEvent, utmLink }: IFolhaDePagamentoProps) => {
  const data: IVantagens[] = json

  const icons: IconProps = {
    digitalAccount: <DigitalAccount color={green[500]} width={16} height={16} />,
    credit: <Credit color={green[500]} width={16} height={16} />,
    forkKnife: <ForkKnife color={green[500]} width={16} height={16} />,
  }

  return (
    <S.Section className='d-flex align-items-end align-items-md-start' role='img' aria-label='Funcionários sorridentes, mexendo no celular.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7'>
            <h2 className='fs-24 lh-30 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 fw-600 text-sora text-grayscale--500 mb-3 font-sora'>Fazendo o pagamento de salário no Inter Empresas, seu funcionário também sai ganhando</h2>
            <S.Vantagens className=''>
              {data.map((card: IVantagens) => (
                <S.Vantagem key={card.icon} className='d-flex align-items-center'>
                  <div>
                    <S.IconCircle className='d-flex justify-content-center align-items-center mr-2'>
                      {icons[card.icon]}
                    </S.IconCircle>
                  </div>
                  <div>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.text }} />
                  </div>
                </S.Vantagem>
              ))}
            </S.Vantagens>
            <S.ButtonLink
              href={utmLink}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_3',
                  section_name: 'Fazendo o pagamento de salário no Inter Empresas, seu funcionário também sai ganhando',
                  element_name: 'Ativar folha de pagamentos',
                  element_action: 'click button',
                  redirect_url: utmLink,
                })
              }}
            >
              Ativar folha de pagamentos
            </S.ButtonLink>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default FazendoOPagamentoDeSalarioNoInterEmpresas
