import { green, white } from 'src/styles/colors'
import styled from 'styled-components'

// Backgrounds
import BackgroundSM from '../../assets/images/background-sm-fazendo-pagamento-de-salario.png'
import BackgroundMD from '../../assets/images/background-md-fazendo-pagamento-de-salario.png'
import BackgroundLG from '../../assets/images/background-lg-fazendo-pagamento-de-salario.png'
import BackgroundXL from '../../assets/images/background-xl-fazendo-pagamento-de-salario.png'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  min-height: 766px;
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-position: top left;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    min-height: 456px;
  }
  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    min-height: 522px;
  }
  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    min-height: 666px;
  }

  padding-bottom: 56px;
  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${green[500]};
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${white};
  }
`

export const Vantagens = styled.div`
  margin: 32px 0;

  div:last-child {
    margin-bottom: 0 !important;
  }
`

export const Vantagem = styled.div`
  margin-bottom: 12px;
  @media ${device.desktopLG} {
    margin-bottom: 30px;
  }
`

export const IconCircle = styled.div`
  background-color: #CCD6D3;
  border-radius: 50%;
  width: 32px;
  height: 32px;
`
