import React from 'react'
import ScrollTo from 'src/components/ScrollTo'

import * as S from './styles'
import { IFolhaDePagamentoProps } from '../../types'

const Hero = ({ sendDatalayerEvent, utmLink }: IFolhaDePagamentoProps) => {
  return (
    <S.Section className='d-flex align-items-center' role='img' aria-label='Funcionários em uma sala reunidos, e nessa sala temos um homem sentado em cima da mesa segurando um tablet mostrando para uma mulher ao seu lado.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-sora text-white mb-2 font-sora'>Simplifique sua folha <span className='d-xl-block'>de pagamento no</span> Inter Empresas</h1>
            <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 fw-400 text-inter text-white'>Ganhe muito mais tempo na hora de realizar o pagamento de seus colaboradores.</p>
            <div className='buttons-container'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name: 'Simplifique sua folha de pagamento no Inter Empresas',
                    element_name: 'Abrir Conta PJ',
                    element_action: 'click button',
                    redirect_url: utmLink,
                  })
                }}
                href={utmLink}
                className='btn--lg btn btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mb-3 mw-100'
              >
                Abrir Conta PJ
              </a>
              <ScrollTo to='#como-ativar-folhda-de-pagamentos' section='dobra_01' sectionName='Simplifique sua folha de pagamento no Inter Empresas' elementName='Saiba como ativar'>
                <button className='btn btn--outline btn--lg btn--orange rounded-3 fs-14 fw-700 text-none mw-100'>Saiba como ativar</button>
              </ScrollTo>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
