import React from 'react'

import Receipt from 'inter-frontend-svgs/lib/orangeds/MD/receipt'
import DepositByBoleto from 'inter-frontend-svgs/lib/orangeds/MD/deposit-by-boleto'
import Spending from 'inter-frontend-svgs/lib/orangeds/MD/spending'
import MoneyUp from 'inter-frontend-svgs/lib/orangeds/MD/money-up'

import json from './assets/data/data.json'
import * as S from './styles'
import { green } from 'src/styles/colors'
import { IconProps } from 'src/components/Layout/Footer/Footer.interface'

import { IFolhaDePagamentoProps } from '../../types'
interface IVantagens {
  icon: string;
  text: string;
}

const SuaFolhaDePagamento = ({ sendDatalayerEvent, utmLink }: IFolhaDePagamentoProps) => {
  const data: IVantagens[] = json

  const icons: IconProps = {
    receipt: <Receipt color={green[500]} width={16} height={16} />,
    depositByBoleto: <DepositByBoleto color={green[500]} width={16} height={16} />,
    spending: <Spending color={green[500]} width={16} height={16} />,
    moneyUp: <MoneyUp color={green[500]} width={16} height={16} />,
  }
  return (
    <S.Section className=''>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 fw-600 text-sora text-white mb-3 font-sora'>
              Sua folha de pagamento com muito mais vantagens
            </h2>
            <p className='fs-16 lh-20 fs-xl-18 lh-xl-22 fw-400 text-inter text-white mb-0'>
              Aproveite um ambiente simples, rápido e sem custos na hora de gerenciar o pagamento de seus colaboradores
              no Inter Empresas.
            </p>
            <div className='btn-div d-none d-md-block'>
              <a
                onClick={() => {
                sendDatalayerEvent({
                    section: 'dobra_2',
                    section_name: 'Sua folha de pagamento com muito mais vantagens',
                    element_name: 'Ativar folha de pagamentos',
                    element_action: 'click button',
                    redirect_url: utmLink,
                  })
                }}
                href={utmLink}
                className='btn--lg btn btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mw-100'
              >
                Ativar folha de pagamentos
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <S.Vantagens className='d-md-flex flex-wrap'>
              {data.map((card: IVantagens) => (
                <S.Vantagem key={card.icon} className='d-flex align-items-center mb-4'>
                  <div>
                    <S.IconCircle className='d-flex justify-content-center align-items-center mr-2'>
                      {icons[card.icon]}
                    </S.IconCircle>
                  </div>
                  <div>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white mb-0' dangerouslySetInnerHTML={{ __html: card.text }} />
                  </div>
                </S.Vantagem>
              ))}
            </S.Vantagens>
            <div className='btn-div d-block d-md-none'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    section_name: 'Sua folha de pagamento com muito mais vantagens',
                    element_name: 'Ativar folha de pagamentos',
                    element_action: 'click button',
                    redirect_url: 'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
                  })
                }}
                href='https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069'
                className='btn--lg btn btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mw-100'
              >
                Ativar folha de pagamentos
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuaFolhaDePagamento
