import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import {
  Hero,
  SuaFolhaDePagamento,
  FazendoOPagamentoDeSalarioNoInterEmpresas,
  ComoAtivarAFolhaDePagamentos,
  FaqSection,
} from './sections/_index'

import pageContext from './pageContext.json'

const FolhaDePagamento = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      <Hero sendDatalayerEvent={sendDatalayerEvent} utmLink={utmLink} />
      <SuaFolhaDePagamento sendDatalayerEvent={sendDatalayerEvent} utmLink={utmLink} />
      <FazendoOPagamentoDeSalarioNoInterEmpresas sendDatalayerEvent={sendDatalayerEvent} utmLink={utmLink} />
      <ComoAtivarAFolhaDePagamentos sendDatalayerEvent={sendDatalayerEvent} />
      <FaqSection faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default FolhaDePagamento
